import { LazyLoadImage } from "react-lazy-load-image-component";
import "../styles/header.scss";
import { Address } from "../../../../interface/axios.types";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetRequest } from "../../../../components/Request";


export default function PublicProfileHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const params = useParams();
  const [store,setStore] = useState<any>()

  async function handleFetchStoreDetails(slug: string) {
    try {
      const response = await GetRequest(
        "store/" + slug + "?profile-detail=true"
      );
      if (response.data) {
        setStore(response.data.data);
      }
    } catch (error: any) {}
  }
  useEffect(() => {
    if (params.slug) handleFetchStoreDetails(params.slug);
  }, []);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    store && <nav className="header_container">
      <Link to="/">
        <img
          src="/modulus-buy.svg"
          className="md:w-[100px] w-[140px]"
          style={{
            width: "60px",
          }}
          alt="logo"
          height="40"
        />
      </Link>
      <div className="name_address" >
        <p>
         <Link to={`/store/${store.slug}`}>
          <span>{store.name}</span>
         </Link>
        </p>
        <p>
          {store?.address?.line1}, {store?.address?.tehsil}
        </p>
      </div>
      <div style={{ display: "flex" }}>
        {/* <ul className="navigation">
          <li>
              <a href={`/store/${params.slug}/about`}>About</a>
            </li>
        </ul> */}
        <div className="image_container" onClick={toggleModal}>
          {store?.profileImage && (
            <LazyLoadImage
              alt={"profile"}
              className="img"
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
              src={store?.profileImage}
            />
          )}
        </div>
      </div>

      {/* Modal overlay */}
      {/* <div
        className={`modal-overlay ${isOpen ? "show" : ""}`}
        onClick={toggleModal}
      ></div> */}

      {/* Side Modal */}
      {/* <div className={`side-modal ${isOpen ? "open" : ""}`}>
        <div className="modal-content">
          <button className="close-btn" onClick={toggleModal}>
            close
          </button>
          <ul>
            <li>
              <a href={`/store/${params?.slug}/about`}>About</a>
            </li>        
          </ul>
        </div>
      </div> */}
    </nav>
  );
}
