import React, { useState } from "react";
import "../styles/carousel.scss";
type Props = {
  imageList: string[];
  imageHeight?: string;
  onClick?: (url: string) => void;
  imageContain?: boolean;
};

function Carousel({
  imageList,
  imageHeight,
  onClick,
  imageContain = true,
}: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalImages = imageList?.length;
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageList.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageList.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="carouselContainer">
      <div className="carousel">
        {totalImages > 1 && (
          <button className="carouselButton prev" onClick={goToPrevious}>
            &#10094;
          </button>
        )}

        <img
          src={imageList[currentIndex]}
          alt={`Slide ${currentIndex}`}
          className="carouselImage"
          style={{
            ...(imageHeight && {
              height: imageHeight,
              maxHeight: imageHeight,
              borderRadius: "0px",
              objectFit: imageContain ? "contain" : "fill",
            }),
          }}
          onClick={() => onClick && onClick(imageList[currentIndex])}
        />

        {totalImages > 1 && (
          <button className="carouselButton next" onClick={goToNext}>
            &#10095;
          </button>
        )}
      </div>

      {totalImages > 1 && (
        <div className="carouselIndicators">
          {imageList.map((_, index) => (
            <span
              key={index}
              className={`indicator ${index === currentIndex ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Carousel;
