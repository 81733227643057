import "./App.css";
import "./index.css";
import { Outlet, Routes } from "react-router-dom";
import Dashboard from "./pages/user/dashboard/Dashboard";
import Products from "./pages/user/products/Products";
import AddShop from "./pages/user/addStore/AddStore";
import Login from "./pages/auth/login/Login";
import Order from "./pages/order/Order";
import ActiveStoreState from "./context/ActiveStore/ActiveStoreState";
import Profile from "./pages/user/profileSetting/Profile";
import Registeration from "./pages/register/Registeration";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./store";
import {
  fetchUserInfoAsyncThunk,
  fetchUserStoreListAsyncThunk,
  isLoginSelector,
  loadingSelector,
  fetchStoreSelfAsyncThunk,
  errorSelector,
  currentUserSelector,
} from "./features/auth/Slice";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Indicator from "./common/Indicators/Indicator";
import PublicHeader from "./layouts/sidebar/PublicHeader";
import PrivateHeader from "./layouts/sidebar/PrivateHeader";
import { Route as ReactRoute } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Home, { Footer } from "./pages/home/Home";
import PrivacyPolicy from "./pages/privacy/Privacy";
import SellerProfile from "./pages/user/sellerProfile/SellerProfile";
import StorePublicProfile from "./pages/user/storePublicProfile/StorePublicProfile";
import Support from "./pages/helpAndSupport/Support";
import { OrderDetailPage } from "./pages/order/OrderDetail";
import { StorePublicProfilePage } from "./pages/user/storePublicProfile";
import StoreProduct from "./pages/user/storePublicProfile/StoreProduct";
import PublicProfileHeader from "./pages/user/storePublicProfile/components/header";
// import StoreProduct from "./pages/user/storePublicProfile/StoreProduct";

function App() {
  const error = useSelector(errorSelector);
  const StoreLayout = () => {
    return (
      <div style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}>
        <PublicProfileHeader />
        <Outlet /> {/* This will render the child routes */}
      </div>
    );
  };
  return (
    <>
      {error ? (
        <Indicator
          message={error.message}
          type={error.type}
          errorId={error.errorId}
        />
      ) : (
        ""
      )}
      {/* <PublicHeader /> */}
      <Routes>
        <ReactRoute path="/" element={<Home />} />
        {/* <ReactRoute path="/login" element={<Login />} /> */}
        <ReactRoute path="/support" element={<Support />} />
        {/* <ReactRoute path="/register" element={<Registeration />} /> */}
        <ReactRoute path="/privacy" element={<PrivacyPolicy />} />
        <ReactRoute path="/store" element={<StorePublicProfile />} />
        <ReactRoute path="/store/:slug" element={<StoreLayout />}>
          <ReactRoute index element={<StorePublicProfilePage />} />
          <ReactRoute path="products" element={<StoreProduct />} />
          <ReactRoute path="product/:productId" element={<StoreProduct />} />
        </ReactRoute>
        {/* <ReactRoute path="/store/:storeId/product" element={<StoreProduct />}/> */}
        <ReactRoute path="/order/:orderId" element={<OrderDetailPage />} />
        <ReactRoute />
      </Routes>
    </>
  );
}

function AuthenticatedApp({ isLogedIn }: any) {
  const error = useSelector(errorSelector);

  return (
    <>
      {error ? (
        <Indicator
          message={error.message}
          type={error.type}
          errorId={error.errorId}
        />
      ) : (
        ""
      )}
      <ActiveStoreState>
        <PrivateHeader />
      </ActiveStoreState>
      <Routes>
        <ReactRoute path="/" element={<Home />} />
        <ReactRoute
          path="/dashboard"
          element={
            <ActiveStoreState>
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            </ActiveStoreState>
          }
        />
        <ReactRoute path="/privacy" element={<PrivacyPolicy />} />
        <ReactRoute
          path="/order"
          element={
            <ProtectedRoute>
              <Order />
            </ProtectedRoute>
          }
        />

        <ReactRoute path="/order/:orderId" element={<OrderDetailPage />} />

        <ReactRoute
          path="/product"
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />

        <ReactRoute
          path="/store/create"
          element={
            <ProtectedRoute>
              <AddShop />
            </ProtectedRoute>
          }
        />

        <ReactRoute
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <ReactRoute path="/support" element={<Support />} />
        <ReactRoute path="/privacy" element={<PrivacyPolicy />} />
        <ReactRoute
          path="/store/:slug"
          element={
            <ProtectedRoute>
              <SellerProfile />
            </ProtectedRoute>
          }
        />

        <ReactRoute />
      </Routes>
    </>
  );
}

export default App;
