import axios from 'axios';

//  const BASE_URL = 'http://localhost:5500/api/v1/';
// const BASE_URL = 'http://192.168.43.90:5500/api/v1/';
const BASE_URL = 'https://api.modulusseventeen.com/api/v1/';

export default axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const axiosRequest = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

// axiosRequest.interceptors.request.use(
//   (config :any) => {
//       // console.log(auth , 'aut in axiosprivate')

//       // if (!config.headers['authorization']) {
//       //     // console.log('inside useaxios')
//       //     config.headers['authorization'] = `Bearer ${auth?.accessToken}`;
//       // }
//       // console.log(config,'in useaxios')
//       return config;
//   }, (error) => Promise.reject(error)
// );

// axiosRequest.interceptors.response.use(
//   response => response?.data,
//   async (error) => {
//       const prevRequest = error?.config;
//       if (error?.response?.status === 401 && !prevRequest?.sent) {
//           prevRequest.sent = true;
//           const response = await axios.get('/refreshtoken', {
//             withCredentials: true
//         });
//           prevRequest.headers['authorization'] = `Bearer ${response.data.accesstoken}`;
//           return axiosRequest(prevRequest)
//       }
//       return Promise.reject(error.response.data);
//   }
// );
