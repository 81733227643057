import { Link, useParams } from "react-router-dom";
import "./styles/profile.scss";
import React, { useEffect, useState } from "react";
import { GetRequest } from "../../../components/Request";
import { IStorePUblicInfo, Product } from "../../../interface/axios.types";
import PublicProfileHeader from "./components/header";
import Banner from "./components/Banner";
import ProductCard from "./components/Product";
import ScrollList from "./components/ScrollList";
import { ICategory } from "../../../features/category/Slice";
import HorizontalScroll from "./components/HorizontalScroll";
import { Footer } from "../../home/Home";

type Props = {};

export const StorePublicProfilePage = (props: Props): any => {
  const [store, setStore] = useState<IStorePUblicInfo | any>();
  const [product, setProductList] = useState<any>({});
  const params = useParams();

  async function handleFetchStoreDetails(slug: string) {
    try {
      const response = await GetRequest(
        "store/" + slug + "?profile-detail=true"
      );
      if (response.data) {
        setStore(response.data.data);
        const productResponse = await GetRequest(
          `seller/store/${response.data.data._id}/home-product`
        );
        setProductList(productResponse.data.data);
      }
    } catch (error: any) {}
  }
  useEffect(() => {
    if (params.slug) handleFetchStoreDetails(params.slug);
  }, []);
  return (
    store &&
    product && (
      <>
        <div className="main">
          <div className="container">
            <Banner imageList={store?.profileDetail?.banner?.imageList} />
            <div className="headingScroll">
              <h5 style={{ margin: 0 }}>Latest</h5>
            </div>
            {product.latestData && (
              <HorizontalScroll
                data={product?.latestData}
                renderItem={(item, index) => (
                  <ProductCard
                    key={item._id + index}
                    product={item}
                    storeId={store._id}
                  />
                )}
              />
            )}
            {product?.groupedData?.map(
              (data: {
                categoryId: string;
                categoryData: ICategory;
                products: Product[];
              }) => (
                <div
                  className="container"
                  style={{ minHeight: "400px" }}
                  key={data.categoryId}
                >
                  <div className="headingScroll">
                    <h5 style={{ margin: 0 }}>{data?.categoryData?.name}</h5>
                    <h5 style={{ margin: 0 }}>
                      {" "}
                      <Link
                        to={`/store/${store._id}/products?category=${data.categoryId}`}
                      >
                        See more
                      </Link>
                    </h5>
                  </div>
                  <HorizontalScroll
                    data={data.products}
                    renderItem={(item, index) => (
                      <ProductCard
                        key={item._id + index}
                        product={item}
                        storeId={store._id}
                      />
                    )}
                  />
                </div>
              )
            )}
            {/* <Footer /> */}
          </div>
        </div>
      </>
    )
  );
};
