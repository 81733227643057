import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { GetRequest } from "../../../components/Request";
import ProductCard from "./components/Product";
import "./styles/storeProduct.scss";
import { Product } from "../../../interface/axios.types";
import ProductDetail from "./components/ProductDetail";
type Props = {};

function StoreProduct({}: Props) {
  const [productList, setProductList] = useState<Product[]>([]);
  const [tempProductList, setTempProductList] = useState<Product[]>([]);
  const params = useParams();
  const [search, setSearch] = useState<string>("");
  const [productDetail, setProductDetail] = useState<Product | null>(null);
  async function handleFetchStoreDetails() {
    try {
      const productResponse = await GetRequest(
        `seller/store/${params.slug}/product`
      );
      setProductList(()=>productResponse.data.data);
    } catch (error: any) {}
  }
  function getProductWithProductId(productId: string) {
    const selectedProduct = productList.find((item) => item._id == productId);
    if (selectedProduct) {
      setProductDetail(()=>selectedProduct);
    }
  }
  useEffect(() => {
    if (productList.length > 0 && params.productId) {
      getProductWithProductId(params.productId);
    }
  }, [productList, params.productId]);
  useEffect(() => {
    handleFetchStoreDetails();
  }, []);
  const filterProducts = (state: Product[], input: string): Product[] => {
    const regex = new RegExp(input, "i");
    const filteredProducts = state.filter((product: Product) =>
      regex.test(product.name)
    );
    return filteredProducts;
  };
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
    setTempProductList(() => filterProducts(productList, event.target.value));
  }
  return (
    <div className="productContainer">
      <div>
        <input
          className="searchBox"
          placeholder="Search product..."
          onChange={handleChange}
          value={search}
        />
      </div>
      {productDetail && !search && (
        <div>
          <ProductDetail product={productDetail} />
        </div>
      )}
      {!search ? (
        <div className="productGrid" id="scrollView">
          {productList.map(
            (item: Product) =>
              productDetail?._id !== item._id && (
                <ProductCard product={item} key={item._id} />
              )
          )}
        </div>
      ) : (
        <div className="productGrid" id="scrollView">
          {tempProductList.map((item: Product) => (
            <ProductCard product={item} key={item._id} />
          ))}
        </div>
      )}
    </div>
  );
}

export default StoreProduct;
