import React, { useState } from "react";
import "../styles/product.scss";
import { Product } from "../../../../interface/axios.types";
import Carousel from "./Carousel";
import { currencyFormatter } from "../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetRequest } from "../../../../components/Request";

type Props = {
  product: Product;
  storeId?: string;
};

const ProductCard: React.FC<Props> = ({ product, storeId }) => {
  const {
    sellerProductDetail: { configList },
  } = product;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  async function handleClickOnBuy() {
    const {
      data: {
        data: { phoneNumberList },
      },
    } = await GetRequest(`store/${product.sellerProductDetail.sellerStoreId}`);
    const message = `I'm interested in buying the product: ${product.name} `;
    const whatsappUrl = `https://wa.me/${
      phoneNumberList[0].countryCode + phoneNumberList[0].nationalNumber
    }?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  }

  function onClose() {
    setShowModal(false);
  }

  return (
    <>
      <div className="product-card">
        <div className="product-image">
          {product.imageList.length > 0 ? (
            <Carousel
              imageList={product.imageList}
              onClick={(url) => {
                setCurrentImage(url);
                setShowModal(true);
              }}
            />
          ) : (
            // <img src={product.imageList[0]} alt={product.name} />
            <img
              src="/images/no-image-available-icon.jpeg"
              alt={product.name}
            />
          )}
        </div>
        <div className="details">
          <h2 className="name">{product.name}</h2>
          <Price
            mrp={product.mrp}
            price={configList.at(0)?.pricePerUnit.price}
          />
        </div>
        <div className="buttonContainer">
          <Link
            to={`/store/${product.sellerProductDetail.sellerStoreId}/product/${product._id}`}
          >
            See Details
          </Link>
          <button type="button" onClick={handleClickOnBuy} className="buy">
            Buy
          </button>
        </div>
      </div>
      {showModal && currentImage && (
        <div className="modalBackdrop" onClick={onClose}>
          <div className="modalContent" onClick={(e) => e.stopPropagation()}>
            <button className="closeButton" onClick={onClose}>
              ×
            </button>
            <img src={currentImage} alt={product.name} />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductCard;

type PriceProps = {
  price: number | undefined;
  mrp?: number | undefined;
};
export function Price({ mrp, price }: PriceProps) {
  if (mrp && price && mrp <= price) {
    return (
      <p className="price">
        <span className="discountedPrice">{currencyFormatter(price)}</span>
      </p>
    );
  }
  if (mrp && price && mrp > price) {
    return (
      <>
        <p className="price">
          <span className="discountedPrice">{currencyFormatter(price)}</span>
          <span className="discountBox">
            {new Intl.NumberFormat("en-US", {
              style: "percent",
            }).format((mrp - price) / mrp)}{" "}
            off
          </span>
        </p>
        <p className="price">
          M.R.P. :{" "}
          <span className="lineDecoration">{currencyFormatter(mrp)} </span>
        </p>
      </>
    );
  }
  if (mrp && !price) {
    return (
      <p className="price">
        <span className="discountedPrice">{currencyFormatter(mrp)}</span>
      </p>
    );
  }
  if (price && !mrp) {
    return (
      <p className="price">
        <span className="discountedPrice">{currencyFormatter(price)}</span>
      </p>
    );
  }
  return <></>;
}
