import React, { useEffect, useRef, useState } from "react";
import "../styles/banner.scss";
import Carousel from "./Carousel";
type Props = {
  imageList:Array<Record<string,string>>
};
const Banner = ({ imageList }: Props) => {
  return (
    <div className="slideshow">
      <Carousel imageList={imageList.map(item=>item.image)} imageHeight="220px" imageContain={false}/>
      {/* <div className={`slideshow-container ${transitionDirection}`}>
        {imageList.map((slide, index) => (
          <div key={slide.id} className={`slide ${index === currentSlide ? 'active' : ''}`}>
            <img src={slide.image} alt={`Slide ${slide.id}`} />
            <div className="slide-text">{slide.text}</div>
          </div>
        ))}
      </div>
      <button className="prev" onClick={handlePrev}>&#10094;</button>
      <button className="next" onClick={handleNext}>&#10095;</button>
      <div className="indicators">
        {imageList.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index === currentSlide ? 'active' : ''}`}
            onClick={() => {
              setTransitionDirection(index > currentSlide ? 'next' : 'prev');
              setCurrentSlide(index);
            }}
          ></span>
        ))} */}
      </div>
  );
};
export default Banner;
