import React, { ReactNode } from "react";
import "../styles/scrollList.scss";
type Props = {
  data: any[];
  title?: string;
  renderItem: (item: any, index: number) => ReactNode;
};
const HorizontalScroll = ({ data, title, renderItem }: Props) => {
  return (
    <div className="categorySection">
      {title && <h2>{title}</h2>}
      <div className="horizontalScroll">
        {data.map((item, index) => renderItem(item, index))}
      </div>
    </div>
  );
};

export default HorizontalScroll;
